export const LOADING_COURSE = 'LOADING_COURSE';
export const LOADED_COURSE = 'LOADED_COURSE';
export const LOADED_COURSE_RESET = 'LOADED_COURSE_RESET';
export const LOADING_COURSE_ERROR = 'LOADING_COURSE_ERROR';
export const RESET_COURSE_STRUCTURE = 'RESET_COURSE_STRUCTURE';

export const LOADED_COURSE_ROLES = 'LOADED_COURSE_ROLES';
export const LOADED_COURSE_ROLES_ERROR = 'LOADED_COURSE_ROLES_ERROR';
export const LOADED_COURSES_INFO = 'LOADED_COURSES_INFO';
export const LOADED_COURSES_INFO_ERROR = 'LOADED_COURSES_INFO_ERROR';

export const LOADING_STUDENT = 'LOADING_STUDENT';
export const LOADED_STUDENT = 'LOADED_STUDENT';
export const LOADING_STUDENT_ERROR = 'LOADING_STUDENT_ERROR';
export const LOADED_STUDENT_RESET = 'LOADED_STUDENT_RESET';

export const DO_LOGIN = 'DO_LOGIN';
export const REFRESH_LOGIN = 'REFRESH_LOGIN';

export const D_SET_SELECTED_COURSE = 'D_SET_SELECTED_COURSE';

export const CLEAN_ERRORS = 'CLEAN_ERRORS';
